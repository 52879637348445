<style scoped>
	.tpm_box{width: 100%;height: 100%;display: flex;flex-direction: column;}
	.tem_body{flex: 1;width: 100%;}
	.tem_header{padding: 5px;display: flex;align-items: center;};
	.tem_body_contair{height: 100%;width: 100%;}
	.tem_footer{text-align: right;padding: 5px;}
	.mb{padding: 0 20px 0 10px;}
</style>
<style>
	table{box-sizing: border-box;}
	/* 用来设置当前页面element全局table 选中某行时的背景色*/
	.el-table__body tr.current-row>td{
	  background-color: #67CD80 !important;
	  color: #fff;
	}
	.mb .el-breadcrumb__inner{font-weight: bold!important;font-size: 1.1rem;}
</style>
<template>
	<div class="tpm_box">
		<div class="tem_header">
			<div class="mb">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item>{{thisPageParams.community.name}}</el-breadcrumb-item>
					<el-breadcrumb-item>{{thisPageParams.build.name}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<el-button type="warning" size="small" @click="add">添加单元<i class="el-icon-circle-plus-outline"></i></el-button>
			<el-button type="warning" size="small" @click="edit">编辑<i class="el-icon-edit"></i></el-button>
			<el-button type="warning" size="small" @click="deleteBuild">删除<i class="el-icon-delete"></i></el-button>
			<el-button type="danger" size="small" @click="thisReload">刷新<i class="el-icon-refresh-right"></i></el-button>
		</div>
		<div class="tem_body" id="tem_body">
			<div class="tem_body_contair" id="table_box" v-loading="tableLoading">
				<el-table :data="tableData" :height="tableHeight" border @row-click="rowClick" highlight-current-row>
					<el-table-column prop="name" label="单元名称" width=""></el-table-column>
				</el-table>
			</div>
		</div>
		<div class="tem_footer">
			<el-pagination @current-change="handleCurrentChange" :current-page="pageNumber" :page-sizes="[20]" :page-size="pageSize"
			 layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['pageParams'],
		data() {
			return {
				thisPageParams: null,
				tableLoading: true,
				tableHeight: 0,
				tableData: [],
				pageNumber: 1,
				pageSize: 20,
				pageTotal: 0,
				selectTableRow: null, // 选中的表格某行
			}
		},
		created: function() {
			_this = this;
			_this.thisPageParams = _this.pageParams;
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				_this.api_getTableData();
			})
		},
		methods: {
			/* * 刷新 */
			thisReload() {
				_this.selectTableRow = null;
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				_this.api_getTableData();
			},
			/* * 切换分页 */
			handleCurrentChange(val) {
				_this.pageNumber = val;
				_this.api_getTableData();
			},
			/* * 获取表格数据 */
			api_getTableData() {
				_this.tableLoading = true;
				_this._getApi('/wy/residenceBuildUnit/getList', {
					build_id: _this.thisPageParams.build.id,
					page: _this.pageNumber,
					limit: _this.pageSize
				}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data.list;
						_this.pageTotal = res.data.count;
						_this.tableData = baseData;
						_this.tableLoading = false;
					} else {

					}
				}).catch((err) => {});
			},

			/* * 添加单元 */
			add() {
				_this.$prompt('请输入单元名称', '添加单元', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(({
					value
				}) => {
					_this._postApi('/wy/residenceBuildUnit/create', {
						name: value,
						residence_id: _this.thisPageParams.community.id,
						build_id:_this.thisPageParams.build.id
					}).then((res) => {
						if (res.code == 1) {
							_this.api_getTableData();
							_this.$emit('childrenEvent', {
								type: 'add'
							});
							_this.$message({
								type: 'success',
								message: '添加成功'
							});
						} else {
							_this.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch((err) => {});
				}).catch(() => {

				});
			},

			/* * 选择某行 */
			rowClick(row, column, event) {
				_this.selectTableRow = row;
			},

			// _ ** 编辑单元
			edit() {
				var baseData = _this.selectTableRow;
				if (!baseData) {
					_this.$alert('请先选择单元进行编辑,点击单元即可选中', '提示', {
						confirmButtonText: '确定',
						callback: action => {}
					});
					return;
				}
				_this.$prompt('', '编辑单元', {
					inputValue: baseData.name,
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(({
					value
				}) => {
					_this._postApi('/wy/residenceBuildUnit/update', {
						id: baseData.id,
						name: value,
						build_id:_this.thisPageParams.build.id
					}).then((res) => {
						if (res.code == 1) {
							_this.selectTableRow = null;
							_this.api_getTableData();
							_this.$emit('childrenEvent', {
								type: 'add'
							});
							_this.$message({
								type: 'success',
								message: '修改成功'
							});
						} else {
							_this.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch((err) => {});
				}).catch(() => {

				});
			},

			// _ ** 删除
			deleteBuild() {
				var baseData = _this.selectTableRow;
				if (!baseData) {
					_this.$alert('请先选择单元进行删除,点击单元即可选中', '提示', {
						confirmButtonText: '确定',
						callback: action => {}
					});
					return;
				}
				var h = _this.$createElement;
				_this.$msgbox({
					title: '不可逆操作',
					message: h('p', null, [
						h('span', null, '确定要删除 '),
						h('span', {
							style: 'color: teal'
						}, baseData.name + ' 吗？')
					]),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';

							_this._postApi('/wy/residenceBuildUnit/delete', {
								id: baseData.id
							}).then((res) => {
								if (res.code == 1) {
									_this.$emit('childrenEvent', {
										type: 'add'
									});
									_this.thisReload();
									_this.selectTableRow = null;
									_this.$message({
										message: '删除成功！',
										type: 'success',
										duration: 1000,
										onClose: function() {
											done();
											instance.confirmButtonLoading = false;
										}
									});
								} else {
									_this.$message({
										message: res.msg,
										type: 'error',
										onClose: function() {
											done();
											instance.confirmButtonLoading = false;
										}
									});
								}
							}).catch((err) => {});
						} else {
							done();
						}
					}
				}).then(action => {

				}).catch(() => {});
			},
		}
	}
</script>
