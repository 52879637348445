<style scoped>
	div,input,textarea{box-sizing: border-box;}
	.page_box_add_community{display: flex;flex-direction: column;overflow: hidden;}
	.page_box_body{overflow: auto;flex: 1;}
	.page_box_footer{padding: 10px 10px 0 10px;text-align: right;}
	/* 表单 */
	
	.z_form_item_row{padding: 10px ;}
	.z_form_item{flex: 1;display: flex;flex-direction: row;align-items:center ;}
	.z_form_item_label{width: 100px;text-align: right;padding-right: 10px;}
	.z_form_item_value{flex: 1;}
	.z_form input{height: 30px;width: 100%;}
	.z_form textarea{height: 80px;width: 100%;padding: 5px;}
</style>
<style>
	.z_form .el-form-item{margin-bottom: 0;}
	.z_form .el-form-item__content{margin-left: 0!important;}
</style>
<template>
	<div class="page_box_add_community">
		<div class="page_box_header"></div>
		<div class="page_box_body">
			<div class="z_form">
				<el-form label-width="80px" :model="form" :rules="rules" ref="form">
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="8">
							<el-form-item prop="name">
								<div class="z_form_item">
									<div class="z_form_item_label">楼层</div>
									<div class="z_form_item_value">
										<el-input placeholder="必填" type="text" v-model="form.floor"></el-input>
									</div>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">房号</div>
								<div class="z_form_item_value">
									<el-input placeholder="必填" v-model="form.room_num"></el-input>
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">房屋唯一编号</div>
								<div class="z_form_item_value">
									<el-input placeholder="必填" v-model="form.code"></el-input>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">建筑面积/m<sup>2</sup></div>
								<div class="z_form_item_value">
									<el-input placeholder="必填" v-model="form.jz_area"></el-input>
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">使用面积/m<sup>2</sup></div>
								<div class="z_form_item_value">
									<el-input placeholder="必填" v-model="form.sy_area"></el-input>
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">房屋性质</div>
								<div class="z_form_item_value">
									<el-select v-model="form.use_type" placeholder="请选择">
										<el-option label="住宅" value="1"></el-option>
										<el-option label="商业" value="2"></el-option>
									</el-select>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">销售状态</div>
								<div class="z_form_item_value">
									<el-select v-model="form.sell_status" placeholder="请选择">
										<el-option label="未售" value="0"></el-option>
										<el-option label="已售" value="1"></el-option>
									</el-select>
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">入住状态</div>
								<div class="z_form_item_value">
									<el-select v-model="form.rz_status" placeholder="请选择">
										<el-option label="未入住" value="0"></el-option>
										<el-option label="已入住" value="1"></el-option>
									</el-select>
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">装修状态</div>
								<div class="z_form_item_value">
									<el-select v-model="form.zx_status" placeholder="请选择">
										<el-option label="未装修" value="0"></el-option>
										<el-option label="已装修" value="1"></el-option>
									</el-select>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						
					</el-row>
				</el-form>
			</div>
		</div>
		<div class="page_box_footer">
			<el-button>取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="onSubmit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props:['pageParams'],
		data() {
			return {
				form: {
					floor: '',
					room_num: '',
					code: '',
					jz_area: '',
					sy_area: '',
					use_type: '',
					sell_status: '',
					rz_status: '',
					zx_status: ''
				},
				rules: null,
				btnSaveLoading: false,
			}
		},
		created: function() {
			_this = this;
		},
		methods: {
			// _** 提交保存
			onSubmit() {
				_this.btnSaveLoading = true;
				var params = _this.form;
				params.residence_id = _this.pageParams.community.id;
				params.build_id =_this.pageParams.build.id;
				params.unit_id = _this.pageParams.units.id;
				_this._postApi('/wy/residenceBuildUnitRoom/create', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent', {
							type:'add'
						});
						_this.$message({
							message: '添加成功！',
							type: 'success',
							duration: 1000,
							onClose: function() {}
						});
						
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			}
		}
	}
</script>
